import { Suspense } from "react";
import Home from "./pages/Home";
import { Route, Switch, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Precios from "./pages/HostingPricing";
import Company from "./pages/About";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import FourZeroFour from "./pages/FourZeroFour";
import Loading from "./components/Loading";
import AlskarMail from "./pages/AlskarMail";
import BannerPromo from "./components/BannerPromo_back2work";
import WebsiteHosting from "./pages/WebsiteHosting";
import Security from "./pages/Security";
import Certificates from "./pages/Certificates";

function App() {

  const location = useLocation();

  return (
    <div className="App relative">
      <BannerPromo />
      <Navbar />
      <Suspense fallback={<Loading />}>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/">
          <Home />
        </Route>
        <Route  path="/pricing">
          <Precios />
        </Route>
        <Route  path="/about">
          <Company />
        </Route>
        <Route path="/alskarmail">
          <AlskarMail />
        </Route>
        <Route  path="/contact">
          <Contact />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/services/ssl-certificates">
          <Certificates />
        </Route>
        <Route path="/services/website-hosting">
          <WebsiteHosting />
        </Route>
        <Route path="/services/security">
          <Security />
        </Route>
        <Route path="*">
          <FourZeroFour />
        </Route>
      </Switch>
      </Suspense>
      <div className="footer">
        <Footer />
      </div>

    </div>
  );
}

export default App;
