import BannerEmpeza from "../components/BannerEmpeza";
// import { CheckCircleIcon } from "@heroicons/react/20/solid";

const values = [
  {
    name: "Be world-class",
    description:
      "We love what we do, and we have high standards for the projects we host! We don't tolerate spam, exploits, malware or any other websites that promote violence or racial/religious intolerance.",
  },
  {
    name: "Integrity",
    description:
      "We differ by our quality of being honest and having strong moral principles, so we act according to our values.",
  },
  {
    name: "Safety and security",
    description:
      "To mitigate the threat of being targeted by DDoS attacks, we use CloudFlare CDN and DNS services. We also want to ensure that your website is only visited by legitimate traffic and visitors by filtering out any malicious IPs, malware bots and other threats.",
  },
  {
    name: "Anti-Spam y Backups Diarios",
    description:
      "SpamAssasin is used to scan and filter all incoming email for spam, phishing, viruses, as well as other email related attacks by spammers and botnets. And because your data is critical, we ensure that your backups are as they should be: intact, usable, and above all kept secure.",
  },
  {
    name: "99.9% uptime commitment",
    description:
      "We are committed to providing optimal availability in all our plans. We monitor not only the network and overall website accessibility, but also all ancillary services such as mail, FTP, MySQL and many more.",
  },
  {
    name: "Respect",
    description:
      "Every human being should be treated equally no matter what their race, color, language, gender, religion, political, national or another status.",
  },
];

// const timeline = [
//   {
//     name: "Founded company",
//     description:
//       "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
//     date: "Aug 2021",
//     dateTime: "2021-08",
//   },
//   {
//     name: "Secured $65m in funding",
//     description:
//       "Provident quia ut esse. Vero vel eos repudiandae aspernatur. Cumque minima impedit sapiente a architecto nihil.",
//     date: "Dec 2021",
//     dateTime: "2021-12",
//   },
//   {
//     name: "Released beta",
//     description:
//       "Sunt perspiciatis incidunt. Non necessitatibus aliquid. Consequatur ut officiis earum eum quia facilis. Hic deleniti dolorem quia et.",
//     date: "Feb 2022",
//     dateTime: "2022-02",
//   },
//   {
//     name: "Global launch of product",
//     description:
//       "Ut ipsa sint distinctio quod itaque nam qui. Possimus aut unde id architecto voluptatem hic aut pariatur velit.",
//     date: "Dec 2022",
//     dateTime: "2022-12",
//   },
// ];


const About = () => {
  return (
    <>
      {/* Background */}
      <div
        className="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#ff8080] to-[#853ac7] opacity-25"
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
        />
      </div>

      {/* Header section */}
      <div className="px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl pt-24 text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            About us
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Since 2009 helping SMEs and entrepreneurs to have their place on the
            Internet.
          </p>
        </div>
      </div>

      {/* Content section */}
      <div className="mx-auto mt-20 max-w-7xl px-6 lg:px-8 relative">
      <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#bb89fc] opacity-30"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-600 lg:max-w-none lg:grid-cols-2">
            <div>
              <p>
                The idea of being able to offer a quality service, that always
                works, to ensure that the service is not interrupted and to be always available in case customers need assistance, Alskar Cloud was born.
              </p>
              <p className="mt-8">
              Our origins are humble. We have gone through many stages and phases of transformation. We changed our name, we changed aesthetically, but our objective and our core was always the same. With much effort and dedication we have been growing over the years and accompanying hundreds of
                customers to have their place on the Internet.
              </p>
            </div>
            <div>
              <p>                
              For several years we have been in the market providing digital
                solutions, offering a web hosting service with datacenters in
                strategic locations, which guarantee maximum performance for all
                customers.
              </p>
              <p className="mt-8">
              From our small office in Argentina, we went on to have our first datacenters in the USA, and even expanded into Europe. Today, we continue to grow, to contribute to the industry and to
                add value to all our customers who rely on us every day to help
                them achieve their goals.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Values section */}
      <div className="mx-auto mt-32 max-w-7xl px-6 lg:px-8 ">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our main focus
          </h2>
        </div>

        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {values.map((value) => (
            <div key={value.name}>
              <dt className="font-semibold text-gray-900">{value.name}</dt>
              <dd className="mt-1 text-gray-600">{value.description}</dd>
            </div>
          ))}
        </dl>
      </div>

      {/* Timeline section */}

      {/* <div className=" mx-auto mt-32 max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {timeline.map((item) => (
            <div key={item.name}>
              <time
                dateTime={item.dateTime}
                className="flex items-center text-sm font-semibold leading-6 text-red-600"
              >
                <svg
                  viewBox="0 0 4 4"
                  className="mr-4 h-1 w-1 flex-none"
                  aria-hidden="true"
                >
                  <circle cx={2} cy={2} r={2} fill="currentColor" />
                </svg>
                {item.date}
                <div
                  className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                  aria-hidden="true"
                />
              </time>
              <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {item.name}
              </p>
              <p className="mt-1 text-base leading-7 text-gray-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div> */}

      <div className="relative max-w-7xl mx-auto py-40 px-4  sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-3xl font-bold text-gray-600 sm:text-4xl sm:tracking-tight lg:text-3xl">
            Our premise is to offer the best cost-benefit ratio
            <br />
            with the best quality-price ratio.
          </p>
        </div>

        {/* PATRON */}
        <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
          <svg
            className="h-[40rem] w-[80rem] flex-none stroke-gray-200"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                width={200}
                height={200}
                x="50%"
                y="50%"
                patternUnits="userSpaceOnUse"
                patternTransform="translate(-100 0)"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y="50%" className="overflow-visible fill-gray-50">
              <path
                d="M-300 0h201v201h-201Z M300 200h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)"
            />
          </svg>
        </div>
        {/* PATRON */}
      </div>

    </>
  );
};

export default About;
