import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import FeaturesMail1 from "../components/FeaturesMail1";

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually (10% OFF)", priceSuffix: "/month" },
];
const tiers = [
  {
    name: "Hobby",
    id: "tier-hobby",
    href: {
      monthly: "https://buy.stripe.com/fZe3cy8hq2qG7NC6oo",
      annually: "https://buy.stripe.com/3cs3cy2X61mC5FubIJ",
    },
    price: { monthly: "$5", annually: "$4.50" },
    description: "The basics for your hobby project or your own newsletter.",
    features: ["Unlimited subscribers", "2.000 sendings", "Ticket support"],
    billFrequency: { monthly: "monthly", annually: "annually" },
    boton: "Start now",
    mostPopular: false,
  },
  {
    name: "Freelancer",
    id: "tier-freelancer",
    href: {
      monthly: "https://buy.stripe.com/aEUdRc8hq6GW8RG3ce",
      annually: "https://buy.stripe.com/7sI6oKcxGaXc7NC6or",
    },
    price: { monthly: "$10", annually: "$9" },
    description: "The essentials to provide your best work for clients.",
    features: [
      "Unlimited subscribers",
      "10.000 sendings",
      "U$D 2.50 per each additional 1000 sendings",
      "Ticket support",
    ],
    billFrequency: { monthly: "monthly", annually: "annually" },
    boton: "Start now",
    mostPopular: true,
  },
  {
    name: "Startup",
    id: "tier-startup",
    href: {
      monthly: "https://buy.stripe.com/fZe8wS9lu4yO1pe7sw",
      annually: "https://buy.stripe.com/9AQbJ4btC4yO0la5kp",
    },
    price: { monthly: "$20", annually: "$18" },
    description: "A plan that scales with your rapidly growing business.",
    features: [
      "Unlimited subscribers",
      "20.000 sendings",
      "U$D 1.50 per each additional 1000 sendings",
      "Dedicated support",
    ],
    billFrequency: { monthly: "monthly", annually: "annually" },
    boton: "Start now",
    mostPopular: false,
  },
  {
    name: "Enterprise",
    id: "tier-enterprise",
    href: "/contact",
    price: "Custom",
    description: "For large organizations with specific needs.",
    features: [
      "Unlimited subscribers",
      "24/7/365 Support",
      "Dedicated Technical Account Manager",
      "Comprehensive onboarding",
    ],
    billFrequency: { monthly: "monthly", annually: "annually" },
    boton: "Contact sales",
    mostPopular: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AlskarMail() {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <div className="bg-white py-24 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-5xl text-center">
          <h2 className="text-base font-semibold leading-7 text-red-600">
            Introducing
          </h2>
          <div className="flex justify-center">
            <img
              src="https://alskarcloud.com/imagenes/logo_mail_positivo.svg"
              alt="Logo Alskar Mail"
            />
          </div>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Personalize your email marketing campaigns
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Our email marketing software has been designed to help companies from
          all industries send and track their communications in each of their
          verticals.
        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? "bg-red-600 text-white" : "text-gray-500",
                    "cursor-pointer rounded-full px-2.5 py-1"
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "ring-2 ring-red-600"
                  : "ring-1 ring-gray-200",
                "rounded-3xl p-8"
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.mostPopular ? "text-red-600" : "text-gray-900",
                  "text-lg font-semibold leading-8"
                )}
              >
                {tier.name}
              </h3>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                {tier.description}
              </p>
              {/* <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price[frequency.value]}</span>
                <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>
              </p> */}
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    tier.featured ? "text-white" : "text-gray-900",
                    "text-4xl font-bold tracking-tight"
                  )}
                >
                  {typeof tier.price === "string"
                    ? tier.price
                    : tier.price[frequency.value]}
                </span>
                {typeof tier.price !== "string" ? (
                  <span
                    className={classNames(
                      tier.featured ? "text-gray-300" : "text-gray-600",
                      "text-sm font-semibold leading-6"
                    )}
                  >
                    {frequency.priceSuffix}
                  </span>
                ) : null}
              </p>
              <div>
                <p className="mt-2 text-xs text-gray-600">
                  Billed {tier.billFrequency[frequency.value]}
                </p>
              </div>
              <a
                href={tier.href[frequency.value]}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-red-600 text-white shadow-sm hover:bg-red-500"
                    : "text-red-600 ring-1 ring-inset ring-red-200 hover:ring-red-300 hover:bg-red-600 hover:text-white",
                  "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                )}
              >
                {tier.boton}
              </a>
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-red-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="container mx-auto px-4 text-center pt-10 text-gray-500">
          <p>
            <i>*VAT may apply</i>
          </p>
        </div>
      </div>

      <FeaturesMail1 />
    </div>
  );
}
