import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "Which plan should I choose?",
    answer:
    "You can upgrade or downgrade your hosting plan anytime without downtime or service interruption. If you are not sure which plan will be most suitable for you, we recommend to start small and scale your way up."
  },
  {
    question: "Can I transfer my website?",
    answer:
      "Of course! If you are using one of the most common Content Management Systems, such as WordPress, there are many tools available to easily migrate your site from one hosting to another. And if you are not using a common Content Management System, or if you need advice with this, just let us know.",
  },
  {
    question: "Is there any limit on sending and receiving emails?",
    answer:
    "The hosting service has the sending of emails limited to 200 emails per hour with a maximum of 500 emails per day and no limits in terms of reception.\n\n We remind you that emails from personal mailboxes cannot be used to send mass promotional and marketing emails. If you wish to carry out such campaigns, we suggest you use AlskarMail."
  },
  {
    question: "What is AlskarMail?",
    answer:
    "AlskarMail is our email marketing platform. With it you can create your email campaigns to send news, newsletters and information to your subscribers.\n\n You can go to the AlskarMail section for more information and prices."
  },
  {
    question: "Will my account be suspended if I exceed my resources?",
    answer:
    "No, we do not suspend accounts for excessive resource usage. All customers can monitor their resource usage in real time through the hosting account control panel. If your website requires more resources, our team will send you a notice with a detailed report and suggestions on how to optimize your website for better resource utilization or possible upgrade options."
  },
  {
    question: "Can I buy a hosting plan without a domain name?",
    answer:
    "Yes, of course! If you want, you can buy the domain from another provider and point it to your Alskar Cloud web hosting. But, if you want to keep your domain name and your website together in one place - which we recommend to facilitate troubleshooting - you can also transfer an existing domain name to Alskar Cloud."
  },
  {
    question: "Is there any type of contract?",
    answer:
    "No, there are no contracts. In other words, you can cancel your service at any time. We still offer subscription periods for our hosting services that are prepaid. However, if you are not happy with the service, you can always cancel your subscription."
  },
  {
    question: "What is the backup policy?",
    answer:
    "For your peace of mind, we perform an automatic daily backup of all the information in your hosting account (emails/databases/files). When a new backup is generated every week, the previous one is deleted.\n\n Likewise, we strongly recommend you to generate your own backups every time you are going to make an important change to your site. For this you have a backup creation/restore tool in your control panel."
  },

  // More questions...
]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }




const Faqs = () => {
    return ( 

    <div className="relative bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">Frequently Asked Questions</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-red-400">
                        <span className="font-medium text-stone-800">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
        
     );
}
 
export default Faqs;