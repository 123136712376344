const WebsiteHosting = () => {
  return (
    <>
      <div className="px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <p className="text-base font-semibold leading-7 text-red-600">
            Website Hosting
          </p>
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Improve your site's trust
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Stay protected against phishing with the aid of security experts.
            We’ll install your SSL certificate, fix redirect errors and even
            renew your cert before it expires completely FREE.
          </p>
        </div>
      </div>
    </>
  );
};

export default WebsiteHosting;
