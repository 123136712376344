import {
    ChartPieIcon,
    ArrowPathIcon,
    ClipboardDocumentListIcon,
    ListBulletIcon,
    InboxArrowDownIcon,
    XCircleIcon,
    CodeBracketSquareIcon,
    CheckIcon,
  } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Beautiful reports.',
    description:
      'See results of every campaign and autoresponders in a beautifully formatted report. Visualize opens, clicks, bounces, complaints and countries with charts & data. You can export segments of subscribers from your report for retargeting.',
    icon: ChartPieIcon,
  },
  {
    name: 'Autoresponders.',
    description: 'Automate your marketing by setting up a series of emails in drip campaigns to automatically follow up with your subscribers at time intervals you set. Or send emails annually or one off emails at a specific date. Keep your subscribers engaged and your brand at the top of their mind.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Rules & Webhooks.',
    description: 'Decide what happens when something happens - by creating "Rules". For example, setup a rule to get notified by email when a scheduled campaign starts sending, trigger a webhook when an autoresponder is sent, unsubscribe someone from a list when they´re signed up to another, and so on.',
    icon: ClipboardDocumentListIcon,
  },
]

const features2 = [
    {
      name: 'List segmentation.',
      description:
        'Research shows sending emails to targeted segments can increase email engagement as well as revenue by more than 25%. Alskar Mail enables you to create segments for any list based on any conditions you define. Choose segments and/or lists to include or exclude in your email campaigns for optimized results.',
      icon: ListBulletIcon,
    },
    {
      name: 'List & subscriber management.',
      description: 'Manage and segment lists & subscribers easily. Custom fields, single/double opt-in, custom subscribe/unsubscribe confirmation page, thank you/good bye emails, subscribe form or API to add users.',
      icon: InboxArrowDownIcon,
    },
    {
      name: 'Bounce, complaint & unsubscribe handling.',
      description: 'Keep your lists clean effortlessly as bounces, complaints and unsubscribes are automatically handled in real time once your newsletter is sent. There is no need for any manual post campaign cleanups, just sit back and watch your campaign report unfold.',
      icon: XCircleIcon,
    },
    {
      name: 'Custom fields.',
      description: 'Create custom fields to store more than just "name" and "email". Not only can you store more information about your subscribers, you can use them for list segmentation or personalization tags in your newsletters for a more personalized experience.',
      icon: CodeBracketSquareIcon, 
    }
  ]

  
  const morefeatures = [
    {
      description: 'Two Factor Authentication',
      icon: CheckIcon,
    },
    {
      description: 'Ready-to-use subscription form',
      icon: CheckIcon,
    },
    {
      description: 'Fast multi-threaded sending',
      icon: CheckIcon,
    },
    {
      description: 'Google Analytics support',
      icon: CheckIcon,
    },
    {
      description: 'Google reCAPTCHA support',
      icon: CheckIcon,
    },
    {
      description: 'Send emails with attachments',
      icon: CheckIcon,
    },
    {
      description: 'Blacklists for emails and/or domains',
      icon: CheckIcon,
    },
    {
      description: 'Single or double opt-in & opt-out',
      icon: CheckIcon,
    },
    {
      description: 'Use personalization tags',
      icon: CheckIcon,
    },
    {
      description: 'Include or exclude lists to send',
      icon: CheckIcon,
    },
    {
      description: 'Store templates',
      icon: CheckIcon,
    },
    {
      description: 'Adjust sending rate',
      icon: CheckIcon,
    },
  ]

export default function FeaturesMail1() {
  return (
    <>
    <div className="overflow-hidden bg-white pt-24 sm:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-red-600">Marketing platform</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Get more from your campaigns</p>
              {/* <p className="mt-6 text-lg leading-8 text-gray-600">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque,
                iste dolor cupiditate blanditiis ratione.
              </p> */}
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-red-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="img/feature_email/reportes.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>


        <div className="overflow-hidden bg-white pt-20 sm:pt-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                        <div className="lg:max-w-lg">
                        {/* <h2 className="text-base font-semibold leading-7 text-red-600">Deploy faster</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque,
                            iste dolor cupiditate blanditiis ratione.
                        </p> */}
                        <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            {features2.map((feature) => (
                            <div key={feature.name} className="relative pl-9">
                                <dt className="inline font-semibold text-gray-900">
                                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-red-600" aria-hidden="true" />
                                {feature.name}
                                </dt>{' '}
                                <dd className="inline">{feature.description}</dd>
                            </div>
                            ))}
                        </dl>
                        </div>
                    </div>
                    <div className="flex items-center justify-end lg:order-first">
                        <img
                        src="img/feature_email/listas.jpeg"
                        alt="Product screenshot"
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                        width={2432}
                        height={1442}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="pt-20 sm:pt-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    {/* <h2 className="text-base font-semibold leading-7 text-indigo-400">Everything you need</h2> */}
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">And even more features</p>
                    {/* <p className="mt-6 text-lg leading-8 text-gray-300">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste
                        dolor cupiditate blanditiis.
                    </p> */}
                </div>
                <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-600 sm:grid-cols-3 lg:mx-0 lg:max-w-none lg:gap-x-16">
                {morefeatures.map((feature) => (
                    <div key={feature.description} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-red-600" aria-hidden="true" />
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                    </div>
                ))}
                </dl>
            </div>
        </div>


    </div>






</>

  )
}
