import {
  MagnifyingGlassIcon,
  LockClosedIcon,
  CheckBadgeIcon,
  CursorArrowRaysIcon,
  BoltIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import BannerEmpeza from "../components/BannerEmpeza";

const actions = [
  {
    title: "Strong Security",
    descripcion:
      "Best encryption for your website, using SHA-256 hashing algorithms and 2048-bit RSA keys.",
    icon: LockClosedIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    title: "Quick",
    descripcion:
      "No additional documentation is needed, get an SSL certificate for your website within an hour.",
    icon: BoltIcon,
    iconForeground: "text-purple-700",

    iconBackground: "bg-purple-50",
  },
  {
    title: "Free site seal",
    descripcion:
      "Get a Free site seal for your website to inform your visitors that their personal data is safe.",
    icon: ShieldCheckIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    title: "SEO Ranking",
    descripcion:
      "Improve your website's Google Rank by protecting it with an SSL certificate.",
    icon: MagnifyingGlassIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
  },
  {
    title: "Avoid phishing",
    descripcion:
      "Avoid Phishing attacks on your website at all costs. Keep your visitors protected!",
    icon: CheckBadgeIcon,
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
  },
  {
    title: "Browser compatible",
    descripcion:
      "Our SSL certificates offer full compatibility with all web browsers.",
    icon: CursorArrowRaysIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
];

const features = [
  {
    name: "Step 1",
    description:
      "The SSL certificate will establish an encrypted connection with the browser of your visitors, each time they access a protected area of your website.",
  },
  {
    name: "Step 2",
    description:
      "The browser of your visitor will display a padlock and an https:// prefix. This will indicate that all data transmitted by your visitor is protected by the SSL certificate.",
  },
  {
    name: "Step 3",
    description:
      "From this moment on, all information passing through your website will be encrypted with a 2048-bit key, completely unbreakable for hackers.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Certificates() {
  return (
    <>
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <p className="text-base font-semibold leading-7 text-red-600">
            SSL Certificates
          </p>
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Improve your site's trust
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Stay protected against phishing with the aid of security experts.
            We’ll install your SSL certificate, fix redirect errors and even
            renew your cert before it expires completely FREE.
          </p>
        </div>
      </div>

      {/* Tabla grid */}
      <div className="bg-gray-100 px-6 py-12  lg:px-8">
        <div className="mx-auto max-w-5xl ">
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 ">
            {actions.map((action, actionIdx) => (
              <div
                key={action.title}
                className={classNames(
                  actionIdx === 0
                    ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                    : "",
                  actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                  actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
                  actionIdx === actions.length - 1
                    ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                    : "",
                  "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-red-500 hover:scale-105 transition-all ease-in duration-200 hover:shadow-lg hover:z-10"
                )}
              >
                <div>
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      "inline-flex rounded-lg p-3 ring-4 ring-white"
                    )}
                  >
                    <action.icon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {action.descripcion}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Los pasos */}
      <div className=" pt-24 pb-12 sm:pt-28">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 relative">
          <div className="mx-auto max-w-7xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              How does it work?
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              The SSL certificate provides an additional layer of security for
              the data passing through your website. Such data is considered the
              login details, payment information, and much more. With more than
              99% of browser recognition and full mobile device support our SSL
              certificates are the perfect solution for your E-Commerce,
              community, or organization website.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          {/* PATRON */}
          <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
            <svg
              className="h-[40rem] w-[80rem] flex-none stroke-gray-200"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                  width={200}
                  height={200}
                  x="50%"
                  y="50%"
                  patternUnits="userSpaceOnUse"
                  patternTransform="translate(-100 0)"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="50%" className="overflow-visible fill-gray-50">
                <path
                  d="M-300 0h201v201h-201Z M300 200h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)"
              />
            </svg>
          </div>
          {/* PATRON */}
        </div>
      </div>
      <div>
        <BannerEmpeza />
      </div>
    </>
  );
}
