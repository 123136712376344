import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function Security() {
  return (
    <>
      <div className="px-6 pt-24 sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <p className="text-base font-semibold leading-7 text-red-600">
            Security
          </p>
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Server protected
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            A global defense network that counteracts botnet attacks, exploits,
            malicious traffic, spam, DNS and HTTP/S DDoS attacks and many other
            threats protecting all types of websites.
          </p>
        </div>
      </div>

      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        {/* <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
          <div
            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#bb89fc] opacity-30"
            style={{
              clipPath:
                "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
            }}
          />
        </div> */}
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start gap-y-16 lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                {/* <p className="text-base font-semibold leading-7 text-red-600">
                  Deploy faster
                </p> */}
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  DNS and DDoS Protection
                </h1>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[38rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[48rem]"
              src="/img/ddos2.jpg"
              alt=""
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <p>
                  Resolving your domain through the DNS service is an essential
                  part of accessing your website and can be a target of DDoS
                  attacks, resulting in website performance degradation or even
                  downtime.
                </p>
                <p className="mt-8">
                  To mitigate such threats, we partnered with the leading
                  managed CDN and DNS service provider CloudFlare. Cloudflare
                  provides ultra-fast CDN and secures DNS built-in service
                  available for free on all hosting plans.
                </p>
                <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                  <div>
                    <dt className="font-semibold text-gray-900">
                      DNS Security
                    </dt>
                    <dd className="mt-1 text-gray-600">
                      <ul role="list">
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> Integrated load balancing</li>
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> Automatic failover</li>
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> Rate limiting and filtering</li>
                      </ul>
                    </dd>
                  </div>
                  <div>
                    <dt className="font-semibold text-gray-900">
                      CDN and DDoS Protection
                    </dt>
                    <dd className="mt-1 text-gray-600">
                      <ul role="list">
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> Fast content delivery</li>
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> Browser integrity check</li>
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> HTTP/S DDoS Protection</li>
                      </ul>
                    </dd>
                  </div>
                </dl>
                <div className="mt-20 flex items-center gap-x-6">
                  <a
                    href="/pricing"
                    className="rounded-md bg-red-500 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-red-600 transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  >
                    Plans & Pricing
                  </a>
                  <a
                    href="/contact"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Get in touch <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative isolate overflow-hidden bg-white px-6 pb-24 sm:pb-32 lg:overflow-visible lg:px-0">

        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start gap-y-16 lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">

                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Firewall
                </h1>
              </div>
            </div>
          </div>
          <div className="-ml-36 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-1 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[38rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[48rem]"
              src="/img/firewall.jpg"
              alt=""
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-2 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-xl">
                <p>
                After resolving your domain, a client makes a connection to your hosting server. At this point, we want to make sure that your website is visited only by legitimate traffic and visitors, filtering any malicious IPs, malware bots and other threats.
                </p>
                <p className="mt-8">
                We also want to make sure that your website is only visited by legitimate traffic and visitors by filtering out any malicious IPs, malware bots and other threats. To achieve this, all hosting services are protected by ConfigServer Security &amp; Firewall.
                </p>
                <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                  <div>
                    <dt className="font-semibold text-gray-900">
                    Traffic Monitoring
                    </dt>
                    <dd className="mt-1 text-gray-600">
                      <ul role="list">
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> Botnets blocker</li>
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> IP Reputation filtering</li>
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> CAPTCHA Human Verification</li>
                      </ul>
                    </dd>
                  </div>
                  <div>
                    <dt className="font-semibold text-gray-900">
                    Website Monitoring
                    </dt>
                    <dd className="mt-1 text-gray-600">
                      <ul role="list">
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> Application-level DoS Protection</li>
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> Request Rate Limit Protection</li>
                        <li className="flex gap-x-2"><CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" /> Whitelist/Blacklist filter</li>
                      </ul>
                    </dd>
                  </div>
                </dl>
                <div className="mt-20 flex items-center gap-x-6">
                  <a
                    href="/pricing"
                    className="rounded-md bg-red-500 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-red-600 transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  >
                    Plans & Pricing
                  </a>
                  <a
                    href="/contact"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Get in touch <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
