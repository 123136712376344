import { Fragment, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";
import BannerAyuda from "../components/BannerAyuda";
import Faqs from "../components/Faqs";

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
];

const promos = [
  {
    discount: "50%",
  },
];

const tiers = [
  {
    name: "Essential",
    id: "tier-essential",
    href: "https://mypanel.alskarcloud.com/order/2",

    price: { monthly: "$4", annually: "$49.50" },
    regularPrice: { monthly: "$8,25", annually: "99" },
    description: "The essential plan to start your project.",
    mostPopular: true,
  },
  {
    name: "Pro",
    id: "tier-pro",
    href: "https://mypanel.alskarcloud.com/order/3",

    price: { monthly: "$8.30", annually: "$99.50" },
    regularPrice: { monthly: "$16,5", annually: "199" },
    description: "Your company is bigger. You are a Pro.",
    mostPopular: false,
  },
  {
    name: "Agency",
    id: "tier-agency",
    href: "https://mypanel.alskarcloud.com/order/4",

    price: { monthly: "$12.45", annually: "$149.50" },
    regularPrice: { monthly: "$24,9", annually: "299" },
    description: "You need much more power. The Agency Plan is for you.",
    mostPopular: false,
  },
];
const sections = [
  {
    name: "Features",
    features: [
      {
        name: "Hosted Websites",
        tiers: { Essential: "Single", Pro: "Unlimited", Agency: "Unlimited" },
      },
      {
        name: "Storage",
        tiers: { Essential: "10 GB", Pro: "20 GB", Agency: "30 GB" },
      },
      {
        name: "Traffic",
        tiers: {
          Essential: "Fair usage",
          Pro: "Fair usage",
          Agency: "Fair usage",
        },
      },
      {
        name: "Unique visits per month (reference)",
        tiers: { Essential: "~ 25 000", Pro: "~ 50 000", Agency: "~ 100 000" },
      },
      {
        name: "Free domain transfer",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "RAM Availability",
        tiers: { Essential: "1.5 GB", Pro: "2 GB", Agency: "3 GB" },
      },
      {
        name: "Inmediate Account Setup",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "DNS Management",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "Addon Domains",
        tiers: { Pro: "Unlimited", Agency: "Unlimited" },
      },
      {
        name: "Sub-domains",
        tiers: {
          Essential: "Unlimited",
          Pro: "Unlimited",
          Agency: "Unlimited",
        },
      },
      {
        name: "FTP Accounts",
        tiers: {
          Essential: "Unlimited",
          Pro: "Unlimited",
          Agency: "Unlimited",
        },
      },
      {
        name: "File Manager",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "Resource Usage Monitoring",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "Multiple PHP versions",
        tiers: {
          Essential: "8.2, 8.1, 8.0, 7.4, 7.3, 7.2, 7.1, 5.6",
          Pro: "8.2, 8.1, 8.0, 7.4, 7.3, 7.2, 7.1, 5.6",
          Agency: "8.2, 8.1, 8.0, 7.4, 7.3, 7.2, 7.1, 5.6",
        },
      },
      {
        name: "PHP Version Switcher",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "Cron Jobs",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "Free Let´s Encrypt SSL",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
    ],
  },
  {
    name: "Email & Database Features",
    features: [
      {
        name: "Email Accounts",
        tiers: {
          Essential: "Unlimited",
          Pro: "Unlimited",
          Agency: "Unlimited",
        },
      },
      {
        name: "Email Forwarders",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      { name: "Webmail", tiers: { Essential: true, Pro: true, Agency: true } },
      {
        name: "IMAP/POP3",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      { name: "SMTP", tiers: { Essential: true, Pro: true, Agency: true } },
      {
        name: "SpamAssassins",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "MySQL databases",
        tiers: {
          Essential: "Unlimited",
          Pro: "Unlimited",
          Agency: "Unlimited",
        },
      },
      {
        name: "phpMyAdmin",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
    ],
  },
  {
    name: "Support",
    features: [
      {
        name: "24/7 online support",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "Ticketing Support",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
      {
        name: "Free SSL Installation",
        tiers: { Essential: true, Pro: true, Agency: true },
      },
    ],
  },
];
const incluyen = [
  "Multilanguage panel",
  "Network Firewall",
  "Specialized support",
  "SSD Drives",
  "Fair Usage Bandwidth*",
  "SSH",
  "Free migrations",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HostingPricing() {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-red-600">
              Pricing
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Plans for all sizes
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            You decide how much space you need.
          </p>

          <div className="mt-16 flex justify-center">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              <RadioGroup.Label className="sr-only">
                Payment frequency
              </RadioGroup.Label>
              {frequencies.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? "bg-red-600 text-white" : "text-gray-500",
                      "cursor-pointer rounded-full px-2.5 py-1"
                    )
                  }
                >
                  <span>{option.label}</span>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>

          {/* xs to lg */}
          <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
            {tiers.map((tier) => (
              <section
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200"
                    : "",
                  "p-8"
                )}
              >
                <h3
                  id={tier.id}
                  className="text-md font-semibold leading-6 text-red-600"
                >
                  {tier.name}
                </h3>
                {promos.map((promo) => (
                  <div className="py-3 discount text-base">
                    <span className="font-bold">{promo.discount}</span> OFF
                  </div>
                ))}
                <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
                  {/* <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                <span className="text-sm font-semibold">/month</span> */}
                  <span className="text-4xl font-bold">
                    {tier.price[frequency.value]}
                  </span>
                  <span className="text-sm font-semibold text-gray-600">
                    {frequency.priceSuffix}
                  </span>
                </p>
                <div className="pt-4 text-xs text-gray-600">
                  Regular Price:{" "}
                  <span className="line-through">
                    {tier.regularPrice[frequency.value]} {frequency.priceSuffix}
                  </span>
                </div>
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "bg-red-600 text-white hover:bg-red-500"
                      : "text-red-600 ring-1 ring-inset ring-red-200 hover:ring-red-300",
                    "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  )}
                >
                  Start now
                </a>
                <ul
                  role="list"
                  className="mt-10 space-y-4 text-sm leading-6 text-gray-900"
                >
                  {sections.map((section) => (
                    <li key={section.name}>
                      <ul role="list" className="space-y-4">
                        {section.features.map((feature) =>
                          feature.tiers[tier.name] ? (
                            <li key={feature.name} className="flex gap-x-3">
                              <CheckIcon
                                className="h-6 w-5 flex-none text-red-600"
                                aria-hidden="true"
                              />
                              <span>
                                {feature.name}{" "}
                                {typeof feature.tiers[tier.name] ===
                                "string" ? (
                                  <span className="text-sm leading-6 text-gray-500">
                                    ({feature.tiers[tier.name]})
                                  </span>
                                ) : null}
                              </span>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </li>
                  ))}
                </ul>
              </section>
            ))}
          </div>

          {/* lg+ */}
          <div className="isolate mt-20 hidden lg:block">
            <div className="relative -mx-8">
              {tiers.some((tier) => tier.mostPopular) ? (
                <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                  <div
                    className="flex w-1/4 px-4"
                    aria-hidden="true"
                    style={{
                      marginLeft: `${
                        (tiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                      }%`,
                    }}
                  >
                    <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                  </div>
                </div>
              ) : null}
              <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                <caption className="sr-only">Pricing plan comparison</caption>
                <colgroup>
                  <col className="w-1/4" />
                  <col className="w-1/4" />
                  <col className="w-1/4" />
                  <col className="w-1/4" />
                </colgroup>
                <thead>
                  <tr>
                    <td />
                    {tiers.map((tier) => (
                      <th
                        key={tier.id}
                        scope="col"
                        className="px-6 pt-6 xl:px-8 xl:pt-8"
                      >
                        <div className="text-base font-semibold leading-7 text-red-600">
                          {tier.name}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <span className="sr-only">Price</span>
                    </th>
                    {tiers.map((tier) => (
                      <td key={tier.promo} className="px-6 pt-2 xl:px-8  z-10">
                        {promos.map((promo) => (
                          <div className="pb-4 discount text-lg">
                            <span className="font-bold">{promo.discount}</span>{" "}
                            OFF
                          </div>
                        ))}

                        <div className="flex items-baseline gap-x-1 text-gray-900">
                          {/* <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                        <span className="text-sm font-semibold leading-6">/month</span> */}
                          <span className="text-5xl font-bold">
                            {tier.price[frequency.value]}
                          </span>
                          <span className="text-sm font-semibold text-gray-600">
                            {frequency.priceSuffix}
                          </span>
                        </div>
                        <div className="pt-2 text-xs text-gray-600">
                          Regular Price:{" "}
                          <span className="line-through">
                            {tier.regularPrice[frequency.value]}{" "}
                            {frequency.priceSuffix}
                          </span>
                        </div>
                        <a
                          href={tier.href}
                          className={classNames(
                            tier.mostPopular
                              ? "bg-red-600 text-white hover:bg-red-500"
                              : "text-red-600 ring-1 ring-inset ring-red-200 hover:ring-red-300",
                            "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          )}
                        >
                          Start now
                        </a>
                      </td>
                    ))}
                  </tr>
                  {sections.map((section, sectionIdx) => (
                    <Fragment key={section.name}>
                      <tr>
                        <th
                          scope="colgroup"
                          colSpan={4}
                          className={classNames(
                            sectionIdx === 0 ? "pt-8" : "pt-16",
                            "pb-4 text-sm font-semibold leading-6 text-gray-900"
                          )}
                        >
                          {section.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                        </th>
                      </tr>
                      {section.features.map((feature) => (
                        <tr key={feature.name}>
                          <th
                            scope="row"
                            className="py-4 text-sm font-normal leading-6 text-gray-900"
                          >
                            {feature.name}
                            <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                          </th>
                          {tiers.map((tier) => (
                            <td key={tier.id} className="px-6 py-4 xl:px-8">
                              {typeof feature.tiers[tier.name] === "string" ? (
                                <div className="text-center text-sm leading-6 text-gray-500">
                                  {feature.tiers[tier.name]}
                                </div>
                              ) : (
                                <>
                                  {feature.tiers[tier.name] === true ? (
                                    <CheckIcon
                                      className="mx-auto h-5 w-5 text-red-600"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <MinusIcon
                                      className="mx-auto h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  )}

                                  <span className="sr-only">
                                    {feature.tiers[tier.name] === true
                                      ? "Included"
                                      : "Not included"}{" "}
                                    in {tier.name}
                                  </span>
                                </>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4 text-center pt-10 text-gray-500">
          <p>
            {/* <i>*Precio en pesos argentinos</i> */}
            <i>*VAT may apply</i>
          </p>

          <h2 className="mt-12 mb-8 text-2xl font-bold text-red-600 sm:text-3xl sm:tracking-tight lg:text-3xl">
            What else do the plans include?
          </h2>

          <ul className="space-y-4">
            {incluyen.map((feature) => (
              <li key={feature} className="flex items-center justify-center">
                <div className="flex-shrink-0">
                  <CheckIcon
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-3 text-base font-medium text-gray-500">
                  {feature}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="pb-10">
        <BannerAyuda />
      </div>
      <div>
        <Faqs />
      </div>
    </>
  );
}
