import { useState, useRef } from "react";
// import { Listbox, Transition } from "@headlessui/react";
// import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import emailjs from "@emailjs/browser";

// const ayuda = [
//   { pedido: "Web hosting" },
//   { pedido: "Email marketing" },
//   { pedido: "Wordpress, SSL or infrastructure consulting" },
//   { pedido: "Contact support" },
//   { pedido: "Other" },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

const ContactForm = () => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    ayuda: "",
    message: "",
  });
  // console.log(values);

//   const [selected, setSelected] = useState(ayuda[0]);

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  //   const handleChange = (event) => {
  //     setSelected(event.target.listbox);
  //   };
//   console.log(selected);

  // CODIGO NUEVO ABAJO

  const [statusMessage, setStatusMessage] = useState("");

  const formulario = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8mnbmok",
        "mensaje_web",
        formulario.current,
        "lfkErKDJ6G3ZnsvRd"
      )
      //   .then(
      //     (result) => {
      //       console.log(result.text);
      //     },
      //     (error) => {
      //       console.log(error.text);
      //     }
      //   );
      //   e.target.reset()
      .then(
        (result) => {
          console.log(result.text, result.status);
          setStatusMessage("Email sent success");
        },
        (error) => {
          console.log(error.text);
          setStatusMessage(`${error.text} happened`);
        }
      );
    e.target.reset();
  };

  // CODIGO NUEVO ARRIBA

  return (
    <form
      ref={formulario}
      onSubmit={sendEmail}
      className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
    >
      <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              First name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                onChange={handleChange}
                name="firstName"
                id="firstName"
                autoComplete="given-name"
                required
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Last name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                onChange={handleChange}
                name="lastName"
                id="lastName"
                autoComplete="family-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                onChange={handleChange}
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <div className="mt-1">
              <label
                htmlFor="ayuda"
                className="block text-sm font-medium leading-6 text-gray-900">
                What do you need?
              </label>
              <select
                onChange={handleChange}
                id="ayuda"
                name="ayuda"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
                defaultValue="select" required>
                <option value="select" disabled>Select One</option>
                <option>Web hosting</option>
                <option>Email marketing</option>
                <option>Wordpress, SSL or infrastructure consulting</option>
                <option>Contact support</option>
                <option>Other</option>
              </select>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                onChange={handleChange}
                name="message"
                id="message"
                rows={4}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                required
                // defaultValue={''}
              />
            </div>
          </div>
        </div>

        <p className="mt-4 text-sm text-sky-700 font-medium">{statusMessage}</p>

        <div
          className="g-recaptcha mt-4 flex justify-end"
          data-sitekey="6LfA-NIUAAAAACG6qPz9jjwO1dfidR1UYLf3x27h"
        ></div>
        <div className="mt-8 flex justify-end">
          <button
            type="submit"
            className="rounded-md bg-red-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            Send message
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
