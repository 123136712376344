export default function LogoClouds() {
    return (
      <div className=" py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-4 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 grayscale"
              src="/img/logos/logo_directadmin.svg"
              alt="Direct Admin"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 grayscale"
              src="img/logos/spamassassin-transparent.png"
              alt="Spamassasin"
              width={158}
              height={48}
            />
            {/* <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
              alt="Tuple"
              width={158}
              height={48}
            /> */}
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1 grayscale"
              src="/img/logos/cloudflare.svg"
              alt="Cloudflare"
              width={158}
              height={48}
            />
            <img
              className="col-span-2  max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1 grayscale"
              src="/img/logos/intel.png"
              alt="Intel"
              width={158}
              height={48}
            />
          </div>
        </div>
      </div>
    )
  }
  