import { Link } from "react-router-dom";

const BannerAyuda = () => {
    return ( 
    <div className="bg-red-50 ">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
          <h2 className="block">You need more?</h2>
          <h2 className="block text-2xl mt-5 font-semibold text-red-600">If you need more power, more customization, more storage,<br /> or just to migrate data from your old platform, contact us.</h2>
        </div>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
            >
              Get in touch
            </Link>
          </div>
        </div>
      </div>
    </div>
     );
}
 
export default BannerAyuda;