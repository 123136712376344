
import { Link } from "react-router-dom";
import { ClockIcon, ArrowDownTrayIcon, ChatBubbleOvalLeftEllipsisIcon, WindowIcon, ShieldCheckIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import LogoClouds from "./locoClouds";
  

  const newfeatures = [
    {
      name: 'Service uptime',
      description:
        'Our team is 100% committed to maintaining above the uptime of 99.9% for our network and your website, email, and core services.',
      icon: ClockIcon,
    },
    {
      name: 'Automatic backups',
      description:
        'Daily and automatic backups of your sites. They are external, they do not take up space in your plan.',
      icon: ArrowDownTrayIcon,
    },
    {
      name: 'Self-management',
      description:
        'Fully managed hosting solution with priority technical support.',
      icon: ChatBubbleOvalLeftEllipsisIcon,
    },
    {
      name: 'SSL Certificate',
      description:
        'A certificate that provides security and prestige to your website.',
      icon: LockClosedIcon,
    },
    {
      name: 'Antivirus & antispam',
      description:
        'Automatic virus scanning and automatic AntiSPAM filter.',
      icon: ShieldCheckIcon,
    },
    {
      name: 'Powered by DirectAdmin',
      description:
        'The lightweight and powerful control panel that is revolutionizing the market.',
      icon: WindowIcon,
    },
  ]

const Features = () => {
    return ( 


    <div className="bg-gray-50 py-24 sm:pt-24 sm:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            What makes us awesome
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Performance that exceeds your expectations and gives you peace of mind
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {newfeatures.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-xl font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-red-50 border border-red-200">
                    <feature.icon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div>
          <LogoClouds />
        </div>
      </div>
      <div className="text-center">
      <Link
        type="button"
        to="/pricing"
        className="rounded-md bg-red-500 px-8 py-3 text-lg font-medium text-white shadow-sm hover:bg-red-600 transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
      >
        Plans & Pricing
      </Link>
      </div>
    </div>

     );
}
 
export default Features;